<template>
  <v-list class="pa-0" two-line>
    <template v-for="(paymentData, paymentDataHeader, index) in payment">
      <v-list-item :key="`item-${index}`">
        <v-list-item-content>
          <v-list-item-title>{{ paymentDataHeader | removeUnderscore }}</v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="paymentData === null">-</span>
            <payment-system-payment-event-view v-else-if="typeof paymentData === 'object'" :payment="paymentData" />
            <span v-else>{{ paymentData }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="index !== paymentDataLength" :key="`divider-${index}`" />
    </template>
  </v-list>
</template>

<script>
import PaymentSystemPaymentEventView from '@/views/payments/payment-events/_partials/PaymentSystemPaymentEventView';
import { toRefs } from '@vue/composition-api';

export default {
  name: 'PaymentSystemPaymentEventView',
  filters: {
    removeUnderscore(string) {
      return string.replace(/_/g, ' ');
    }
  },
  props: {
    payment: Object,
  },
  components: {
    PaymentSystemPaymentEventView,
  },
  setup(props) {
    const { payment } = toRefs(props);
    const paymentDataLength = Object.keys(payment.value).length - 1;

    return {
      paymentDataLength
    };
  }
};
</script>
