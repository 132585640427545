<template>
  <v-list two-line>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Payment ID</v-list-item-title>
        <v-list-item-subtitle class="payment-id">
          <span>{{ payment.paymentId }}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="copyValue(payment.paymentId)"
                class="pl-3 pr-2"
              >
                {{ icons.mdiContentCopy }}
              </v-icon>
            </template>
            <span>Copy</span>
          </v-tooltip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Payment Provider ID</v-list-item-title>
        <v-list-item-subtitle class="provider-payment-id">
          <span>{{ payment.providerPaymentId || '-' }}</span>
          <v-tooltip v-if="payment.providerPaymentId" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="copyValue(payment.providerPaymentId)"
                class="pl-3 pr-2"
              >
                {{ icons.mdiContentCopy }}
              </v-icon>
            </template>
            <span>Copy</span>
          </v-tooltip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Order ID</v-list-item-title>
        <v-list-item-subtitle class="order-id">
          <span>{{ payment.orderId }}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="copyValue(payment.orderId)"
                class="pl-3 pr-2"
              >
                {{ icons.mdiContentCopy }}
              </v-icon>
            </template>
            <span>Copy</span>
          </v-tooltip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Amount</v-list-item-title>
        <v-list-item-subtitle class="amount">{{ payment.amount }} {{ payment.currency }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Refunded Amount</v-list-item-title>
        <v-list-item-subtitle class="refunded-amount">
          <span v-if="payment.refundedAmount">{{ payment.refundedAmount }} {{ payment.currency }}</span>
          <span v-else>-</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Service</v-list-item-title>
        <v-list-item-subtitle class="service">{{ payment.service }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Status</v-list-item-title>
        <v-list-item-subtitle>
          <v-chip
            small
            :color="resolvePaymentEventStatusVariant(payment.status)"
            :class="`${resolvePaymentEventStatusVariant(payment.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolvePaymentEventStatusName(payment.status) }}
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Transaction Description</v-list-item-title>
        <v-list-item-subtitle class="transaction-description">{{ payment.transactionDescription }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Card Number</v-list-item-title>
        <v-list-item-subtitle class="card-number">{{ payment.cardNumber }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Card Holder Name</v-list-item-title>
        <v-list-item-subtitle class="card-holder-name">{{ payment.cardHolderName }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Company Name</v-list-item-title>
        <v-list-item-subtitle class="company-name">{{ payment.website && payment.website.company ? payment.website.company.name : '' }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Account</v-list-item-title>
        <v-list-item-subtitle class="account">{{ payment.website && payment.website.company ? payment.website.company.accountName : '' }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Website</v-list-item-title>
        <v-list-item-subtitle class="website-name">{{ payment.website.name }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import usePaymentEventsList from '@/views/payments/payment-events/usePaymentEventsList';
import { copyValue } from '@core/utils';
import { mdiContentCopy } from '@mdi/js';

export default {
  props: {
    payment: Object,
  },

  setup() {
    const {
      resolvePaymentEventStatusVariant,
      resolvePaymentEventStatusName,
    } = usePaymentEventsList({}, false);

    return {
      resolvePaymentEventStatusVariant,
      resolvePaymentEventStatusName,
      copyValue,
      icons: {
        mdiContentCopy
      }
    };
  }
};
</script>
