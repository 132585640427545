<template>
  <v-container>
    <v-card class="mb-7">
      <v-card-title>
        <div class="mb-2 mb-sm-0 w-full">
          <v-icon @click="$router.back()">{{ icons.mdiArrowLeftBoldCircleOutline }}</v-icon>
          Payment View
        </div>
      </v-card-title>
    </v-card>
    <v-row>
      <v-col cols="12" md="6">
        <v-card :loading="loading" class="mb-5" id="merchanto-payment-details">
          <v-card-title>Merchanto Payment Details</v-card-title>
          <v-card-text v-if="paymentData && paymentData.merchantoPaymentData">
            <merchanto-payment-event-view :payment="paymentData.merchantoPaymentData" />
          </v-card-text>
        </v-card>

        <v-card :loading="loading" id="payment-logs">
          <v-card-title>Payment Logs</v-card-title>
          <v-card-text v-if="paymentData && paymentData.merchantoPaymentData">
            <payment-log-view :logs="paymentData.merchantoPaymentData.logs" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card :loading="loading" id="payment-system-details">
          <v-card-title>Payment System Details</v-card-title>
          <v-card-text v-if="paymentData && paymentData.systemPaymentData">
            <payment-system-payment-event-view :payment="paymentData.systemPaymentData" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref } from '@vue/composition-api';
import { mdiArrowLeftBoldCircleOutline } from '@mdi/js';
import MerchantoPaymentEventView from '@/views/payments/payment-events/_partials/MerchantoPaymentEventView';
import PaymentSystemPaymentEventView from '@/views/payments/payment-events/_partials/PaymentSystemPaymentEventView';
import PaymentLogView from '@/views/payments/_partials/PaymentLogView';

export default {
  components: {
    MerchantoPaymentEventView,
    PaymentSystemPaymentEventView,
    PaymentLogView,
  },
  setup() {
    const paymentData = ref(null);
    const loading = ref(true);

    store
      .dispatch('paymentEvent/fetchPaymentEventsById', router.currentRoute.params.id)
      .then(response => {
        paymentData.value = response.data;
      })
      .catch(error => {
        if (error.response.status === 404) {
          paymentData.value = {};
        }
      })
      .finally(() => {
        loading.value = false;
      });

    return {
      paymentData,
      loading,
      icons: {
        mdiArrowLeftBoldCircleOutline,
      }
    };
  },
};
</script>
